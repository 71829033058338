<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <address-type-form :address-type="addressType">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </address-type-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import AddressTypeForm from "../../../components/forms/address-type-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";

export default {
    components: {CallbackButton, AddressTypeForm},

    computed: {
        ...mapGetters({
            addressType: 'addressType/item'
        }),

        title: function () {
            return this.addressType.id ? this.$tc('addresses::address_types.address_type', 2).ucFirst() + ' / ' + this.addressType.name : this.$t('base.create_new_item', {item: this.$tc('addresses::address_types.address_type', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/master-data/address-types')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('addressType/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('addressType/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
